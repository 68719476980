.custom-form-field {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
}

.custom-form-field .c-form-field__label {
    flex: 1;
}

.generic-upload {
    width: 220px;
}

.warning-upload-wrapper {
    display: flex;
    flex-direction: column;
}