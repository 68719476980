.required-fields-tip {
  padding: 10px 0;
  font-size: 16px;
  padding-bottom: 20px;
}

.reference-data-required-fields-tip-wrapper .required-fields-tip {
  padding-bottom: 55px;
  margin-top: -14px;
}
