.tippy-content {
    overflow-y: hidden !important;
}

.info-text {
  font-size: 0.72222rem;
  line-height: 1.5;
  color: #4c5356;
  font-weight: 400;
}

.grey-out-tooltip {
  opacity: 0.5;
}

